<template>
  <h1 class="wel">欢迎</h1>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
    }
  },
  methods: {

  },
created() {

  },
mounted() {

  },
}
</script>

<style scoped>
  .wel{
    text-align: center;
  }
</style>